<template>
  <div
    class="inputo p-1 text-c14"
    :class="{'error': error,
             'success': success === true,
             'deseable': deseable === true}"
    :style="{'height': height, 'border': border, 'border-radius': radius, 'background-color': background}"
  >
    <div
      class="flex h-full items-center"
    >
<!--      <icon-->
<!--          :data="icons.ic_lock"-->
<!--          height="17"-->
<!--          width="17"-->
<!--          class="mr-3"-->
<!--          original-->
<!--          @click="afficher"-->
<!--      />-->
      <input
        v-model="value"
        :type="typePassword"
        class="w-full h-full pl-3 pr-3"
        :placeholder="placeholder"
        :style="{'font-size': size, 'font-weight': weight}"
      >
      <icon
          :data="icons.ic_eye"
          height="25"
          width="25"
          class="mr-2 cursor-pointer"
          original
          @click="afficher"
      />
    </div>
    {{ sendValue }}
  </div>
</template>

<script>
import ic_lock from '../../../assets/icons/lock.svg'
import ic_eye from '../../../assets/icons/eyeBlue.svg'

export default {
  name: 'Index',
  components: {
  },

  props: {
    deseable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: null
    },
    icon: {
      type: Object,
      default: null
    },
    valueT: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '14px'
    },
    weight: {
      type: String,
      default: '400'
    },
    height: {
      type: String,
      default: '40px'
    },
    border: {
      type: String,
      default: '1px solid rgba(93, 56, 224, 0.3)'
    },
    radius: {
      type: String,
      default: '4px'
    },
    background: {
      type: String,
      default: 'white'
    },
    maxLength: {
      type: Number,
      default: null
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      error: false,
      success: false,
      value: '',
      typePassword: 'password',
      icons: {
        ic_eye,
        ic_lock
      }
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/

        if (this.isRequired){
          if (this.value.length === 0 && this.value.length !== ''){
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.error = true
          }

          if (this.value.length > 0 && this.value.length !== ''){
            if(regularExpression.test(this.value)) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.success = true
              this.$emit('info', this.value)
            } else {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.error = true
            }

          }
        } else {
          if (this.value.length > 0 && this.value.length !== ''){
            // console.log(regularExpression.test(this.value))
            if(regularExpression.test(this.value)) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.success = true
              this.$emit('info', this.value)
            } else {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.error = true
            }

          }
        }
    },
  },

  created() {
    this.value = this.valueT
  },

  methods: {

    afficher(){
      if (this.typePassword === 'password') {
        this.typePassword = 'text'
      } else {
        this.typePassword = 'password'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  font-family: $font-default;
}
input{
  color: #0e0e0e;
  background-color: transparent;
  border-radius: 20px;
}
.inputo:focus-within {
  border: 1px solid $hover-color!important;
}
.inputo:hover {
  border: 1px solid $hover-color!important;
}
.error{
  border: 1px solid #ea9f9f!important;
}
.success{
  border: 1px solid rgb(92, 224, 56) !important;
}
.lab{
  background: #F2F2F2;
  border-radius: 10px;
  height: 35px;
  font-size: 14px;
  line-height: 27px;
}
.deseable{
  background: #eeeeee;
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .inputo{
    height: 65px;
  }
  input{
    font-size: 18px;
  }
}

@media screen and (max-width: 700px){
  .inputo {
    height: 55px;
  }
  input{
    font-size: 14px;
  }
}
</style>
